import React, { ReactElement } from 'react';
import { NavItemElements, NavItemProps } from '../types';
import NavItem from './nav_item';

interface NavMenuItemsProps {
	children: NavItemElements;
}

export const NavMenuItems = ({ children }: NavMenuItemsProps) => {
	return (
		<>
			{children.reduce<Array<ReactElement<NavItemProps> | null | undefined>>(
				(acc, child) => {
					if (child) {
						const { shouldHide, shouldHideMenuItem, title } = child.props;
						if (title && !shouldHideMenuItem) {
							acc.push(
								<NavItem shouldHide={!!shouldHide} title={title} key={title} />,
							);
						}
					}
					return acc;
				},
				[],
			)}
		</>
	);
};
