import React, { memo } from 'react';
import SecondaryCTA from '../../../../components/secondary_cta';
import Styles from './styles.scss';

const ReviewSecondaryCTA = memo(() => {
	return (
		<SecondaryCTA
			className={Styles.secondaryCta}
			sourceContent="Reviews CTA"
			headerText="Any questions?"
			linkText="Start a conversation"
			icon="chat"
			placeholderRfqText="Tell us what you’re looking for"
		/>
	);
});

export default ReviewSecondaryCTA;
