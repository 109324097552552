import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { fetchingMember } from '@redux/index';
import { useEffect, useRef } from 'react';
import AnalyticsConstants from '../../../../constants/analytics';
import { type buildProperties } from '../helpers';

export const useFetchingMember = (
	properties: ReturnType<typeof buildProperties>,
) => {
	const isFetchingMember = useAppSelector(fetchingMember);
	const isFetchingMemberRef = useRef(isFetchingMember);
	const { track } = useAnalyticsContext();

	useEffect(() => {
		if (isFetchingMember !== isFetchingMemberRef.current) {
			track({
				event: AnalyticsConstants.STOREFRONT_VIEWED_VTR,
				properties,
			});
			isFetchingMemberRef.current = isFetchingMember;
		}
	}, [track, properties, isFetchingMember]);
};
