import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback, useMemo } from 'react';
import AnalyticsConstants from '../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../decorators/vendorAnalytics';

export const useViewMoreOrFetchReviews = (
	reviewCount: number,
	amountToRender: number,
	vendorAnalytics: VendorAnalytics,
	fetchReviews: () => void,
	updateAmountToRender: () => void,
) => {
	const { track } = useAnalyticsContext();
	const shouldUpdate = useMemo(
		() => reviewCount > amountToRender,
		[reviewCount, amountToRender],
	);

	return useCallback(() => {
		track({
			event: AnalyticsConstants.SEE_MORE_REVIEWS,
			properties: vendorAnalytics.baseEventProps(),
		});

		if (shouldUpdate) {
			updateAmountToRender();
		} else {
			fetchReviews();
		}
	}, [
		track,
		shouldUpdate,
		vendorAnalytics,
		fetchReviews,
		updateAmountToRender,
	]);
};
