import { AboutHighlights } from '@components/about-highlights/about-highlights';
import { useViewReviews } from '@components/reviews/hooks/use-view-storefront-reviews';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { Editorial, Hero as UnionHero } from '@xo-union/tk-ui-typography';
import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Decorated } from 'types/vendor';
import InlineRfqModal from '../../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../pages/Storefront/containers/cta_box/RfqButton';
import ContactButtons from '../../components/contact_buttons';
import SocialSharing from '../../components/social_sharing';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import Social from '../contact/components/social';
import Address from './components/address';
import FavoritesButton from './components/favorites_button';
import ReviewSummary from './components/review_summary';
import Styles from './styles.scss';

interface VendorInfoProps {
	isNavSticky?: boolean;
}

const VendorInfo: FC<VendorInfoProps> = (props) => {
	const { isNavSticky } = props;
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const membership = useAppSelector((state) => state.membership);
	const isMobile = useAppSelector(selectIsMobile);
	const viewport = useAppSelector((state) => state.viewport);
	const isLiteStorefront = useLiteStorefront();
	const shouldShowScannability = useStorefrontFeatureScannability();

	const [isLandscape] = useScreenDetails();
	const { rating, totalCount } = useViewReviews();
	const hideFavorite = useMemo(
		() => isMobile && !isLandscape,
		[isMobile, isLandscape],
	);
	const formContext = useInlineRfqForm({
		initiator: 'Lite Storefront Header',
		headerText: 'Get a personalized quote',
		subheaderText:
			vendor.categoryCode === 'BWP'
				? 'Contact for Appointment'
				: 'Contact for Pricing',
	});

	const getFavoritesContainerClasses = () => {
		const styles = [];

		if (vendor?.isPaid) {
			styles.push(Styles.favoritesButtonContainer);
		} else {
			styles.push(Styles.favoritesButtonUnpaidContainer);
		}

		if (isMobile) {
			styles.push(Styles.favoritesButtonContainerGallery);
		}

		return classnames(...styles);
	};

	const reviewStarSize = isMobile ? 'sm' : 'md';

	const getContactOrSocial = () => {
		if (shouldShowScannability) {
			return null;
		}
		const socialOrLinks = <Social social={vendor.social} vendor={vendor} />;
		return !isMobile ? socialOrLinks : <ContactButtons />;
	};

	return (
		<div id="section-vendor-info" className={Styles.sectionVendorInfo}>
			<div className={Styles.vendorNameFavoritesContainer}>
				<div className={Styles.vendorNameContainer}>
					{shouldShowScannability ? (
						<UnionHero as="h1" size="sm">
							{vendor?.name}
						</UnionHero>
					) : (
						<h1 className={Styles.vendorName}>{vendor?.name}</h1>
					)}
				</div>
				<div className={Styles.buttonsContainer}>
					<div className={getFavoritesContainerClasses()}>
						{!hideFavorite && !isNavSticky && (
							<FavoritesButton membership={membership} vendor={vendor} />
						)}
					</div>
					{!hideFavorite && !isNavSticky && (
						<SocialSharing
							vendor={vendor}
							icon="share_ios"
							viewport={viewport}
						/>
					)}
				</div>
			</div>
			{shouldShowScannability && <Headline headline={vendor?.headline} />}
			<div className={Styles.vendorRatingAddressWrapper}>
				<ReviewSummary
					vendor={vendor}
					rating={rating}
					totalCount={totalCount}
					extendedBow={true}
					starSize={reviewStarSize}
					showLabelCount={shouldShowScannability}
					showBow={!shouldShowScannability}
				/>
				<Address
					vendor={vendor}
					showIcon={shouldShowScannability}
					link={shouldShowScannability}
					showAddress={!shouldShowScannability}
				/>
				{getContactOrSocial()}
				{isLiteStorefront && (
					<div className={Styles.rfqWrapper}>
						<InlineRfqModal context={formContext} />
						<RFQButton
							type="message"
							color="secondary"
							size="md"
							label="Request Quote"
							handleCTAClick={formContext.openModal}
						/>
					</div>
				)}
			</div>
			<AboutHighlights />
		</div>
	);
};

const Headline = ({ headline }: { headline: string | null }) => {
	if (!headline) {
		return null;
	}

	return (
		<Editorial className={Styles.headline} bold>
			{headline}
		</Editorial>
	);
};

export default VendorInfo;
