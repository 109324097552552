import React, { FC } from 'react';
import { Decorated } from 'types/vendor';
import VendorHelper from '../../../../../helpers/vendor';
import { isClaimed } from '../../helpers';
import MessageVendorCTA from '../message_vendor_cta';
import TeamInfo from './components/TeamInfo';

interface Props {
	vendor: Decorated;
}

interface InferredAsChildrenOfNavProps {
	title: string;
	shouldHide: boolean;
}

const Bio: FC<Props & Readonly<InferredAsChildrenOfNavProps>> = (props) => {
	const { vendor } = props;
	const { isPaid, claimedStatus } = vendor;
	const shouldShowMessageVendorCta = isPaid && isClaimed(claimedStatus);

	return (
		<div>
			{VendorHelper.bioHasContent(vendor.bio) && <TeamInfo bio={vendor.bio} />}
			{shouldShowMessageVendorCta && (
				<MessageVendorCTA size="md" label="Message vendor" />
			)}
		</div>
	);
};

export default Bio;
