import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Img } from '@xo-union/tk-component-picture';
import { H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Decorated } from 'types/vendor';
import { NavItemProps } from '../nav/types';
import ContactInfo from './components/contact_info';
import Social from './components/social';
import Styles from './styles.scss';

interface VendorInfoProps {
	vendor: Decorated;
}

const VendorInfo: FC<VendorInfoProps> = ({ vendor }) => {
	return (
		<div>
			<ContactInfo />
			<Social social={vendor.social} vendor={vendor} isFooter />
		</div>
	);
};

interface ContactProps {
	vendor: Decorated;
	children: ReactNode;
	showMessageVendorCTA: boolean;
}

const Contact: FC<ContactProps & NavItemProps> = (props) => {
	const {
		children = null,
		showMessageVendorCTA = false,
		vendor,
		className,
	} = props;
	const logoUrl = vendor?.logo?.url;

	const isLiteStorefront = useLiteStorefront();

	const hasNoAddress =
		!vendor?.address?.address &&
		!vendor?.address?.city &&
		!vendor?.address?.state;
	const hasNoServiceArea = !vendor?.serviceArea;

	if (isLiteStorefront && hasNoAddress && hasNoServiceArea) {
		return null;
	}

	return (
		<div
			className={classNames(Styles.contactInfoSection, className)}
			id="contact"
		>
			<Row hGutters={false}>
				<Column sm="12" md="8" orderMd="1">
					<H3 className={Styles.title}>Contact</H3>
					<VendorInfo vendor={vendor} />
				</Column>
				{logoUrl && (
					<Column md="4" orderMd="2" className={Styles.logo}>
						<Img
							src={`${logoUrl}~rs_216.104.fit?quality=90`}
							alt="Vendor Logo"
						/>
					</Column>
				)}
			</Row>
			{showMessageVendorCTA && !isLiteStorefront && (
				<Row>
					<Column
						sm="12"
						md="12"
						orderMd="1"
						className={Styles.messageVendorCta}
					>
						{children}
					</Column>
				</Row>
			)}
		</div>
	);
};

export default Contact;
