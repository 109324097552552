import { useEffect } from 'react';
import { FEATURE_FLAGS, useFeatureFlags } from '../../../feature-flags';

export const useStorefrontFeatures = () => {
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (featureFlags[FEATURE_FLAGS.ELEMONOPY_TEST]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Sample Feature Flag enabled');
		}
		if (featureFlags[FEATURE_FLAGS.STOREFRONT_AVAILABILITY]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Availability Feature Flag enabled');
		}
		if (featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Scannability Feature Flag enabled');
		}
		if (featureFlags[FEATURE_FLAGS.STOREFRONT_DETAILED_PRICING]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Detailed Pricing enabled');
		}
		if (featureFlags[FEATURE_FLAGS.UNIFIED_VENDOR_BIO]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Unified Vendor BIO enabled');
		}
	}, [
		featureFlags[FEATURE_FLAGS.ELEMONOPY_TEST],
		featureFlags[FEATURE_FLAGS.STOREFRONT_AVAILABILITY],
		featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY],
		featureFlags[FEATURE_FLAGS.STOREFRONT_DETAILED_PRICING],
		featureFlags[FEATURE_FLAGS.UNIFIED_VENDOR_BIO],
	]);

	return featureFlags;
};
