import { Body1 } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import Styles from './styles.scss';

interface Props {
	overallRating: number;
}

const rating = (overallRating: number, toFixed: number) => {
	if (overallRating % 1 === 0) {
		return overallRating.toFixed(toFixed);
	}

	return overallRating === 0 ? overallRating : overallRating.toFixed(1);
};

// Product does not want to render a `0.0` value, rather display it as `0`
// All other numbers are allowed to have the decimal place: `4.0`
const ReviewRating: VFC<Props> = ({ overallRating = 0 }) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	const toFixed = shouldShowScannability ? 1 : 0;
	return (
		<Body1 className={Styles.overallRating} bold aria-hidden>
			{rating(overallRating, toFixed)}
		</Body1>
	);
};

export default ReviewRating;
