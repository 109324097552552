import cx from 'classnames';
import React, { FC } from 'react';

import Styles from './styles.scss';

export interface NavSectionProps {
	children: React.ReactNode;
	className?: string;
	navId: string;
	shouldHide?: boolean;
}

const NavSection: FC<NavSectionProps> = ({
	children,
	className = '',
	navId,
	shouldHide = false,
}) => {
	return shouldHide ? null : (
		<div
			id={navId}
			data-testid={`nav-section-${navId}`}
			className={cx(className, Styles.sectionWrapper)}
		>
			{children}
		</div>
	);
};

export default NavSection;
