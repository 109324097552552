import {
	NextButton,
	PrevButton,
} from '@components/carousel-buttons/carousel-buttons';
import { TeamMemberCard } from '@components/team-member-card/team-member-card';
import { compose } from '@xo-union/react-css-modules';
import { H3 } from '@xo-union/tk-ui-typography';
import { Carousel, NavigationButtonProps } from '@xo-union/ui-carousel';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { teamMembersAtom } from '../../../../jotai/storefront/organization';
import { NavItemProps } from '../nav/types';
import Style from './meet_the_team.scss';

export const MeetTheTeam = ({ className }: NavItemProps) => {
	const [carouselIndex, setCarouselIndex] = useState(0);
	const teamMembers = useAtomValue(teamMembersAtom);

	const onNavigationIndexChange = (nextIndex: number) => {
		setCarouselIndex(nextIndex);
	};

	const extraProps: { alignment?: () => string } = {};

	if (teamMembers.length === 2) {
		extraProps.alignment = () => 'left';
	}

	const nextTeamButton = (props: NavigationButtonProps) => (
		<NextButton
			{...props}
			className={classNames(props.className, Style.upButton)}
		/>
	);

	const prevTeamButton = (props: NavigationButtonProps) => (
		<PrevButton
			{...props}
			className={classNames(props.className, Style.upButton)}
		/>
	);

	if (teamMembers.length > 0) {
		return (
			<div className={className}>
				<H3 className={Style.title}>Meet the team</H3>
				<div>
					{teamMembers.length > 1 ? (
						<Carousel
							gutter={20}
							minSlideWidth={255}
							maxSlideWidth={255}
							renderNextButton={nextTeamButton}
							renderPrevButton={prevTeamButton}
							onNavigationIndexChange={onNavigationIndexChange}
							navigationIndex={carouselIndex}
							classes={compose({ slidesList: Style.slideList })}
							{...extraProps}
						>
							{teamMembers.map((member) => (
								<TeamMemberCard
									key={`card_${member.name}`}
									isReduced={true}
									memberBio={member}
								/>
							))}
						</Carousel>
					) : (
						<TeamMemberCard memberBio={teamMembers[0]} />
					)}
				</div>
			</div>
		);
	}

	return null;
};
