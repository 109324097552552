import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { showTourModal } from '@redux/media/actionTypes';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import ClientSideRenderer from '../../../../components/client_side_renderer';

interface TourLightboxProps {
	showTourModalMedia: (show: boolean, url: string) => Media.ShowTourModal;
	url: string;
	isOpen: boolean;
}

export const TourLightbox: VFC<TourLightboxProps> = (props) => {
	const { isOpen, showTourModalMedia, url } = props;

	const closeLightbox = () => {
		showTourModalMedia(false, '');
	};

	if (!isOpen) {
		return null;
	}

	return (
		<ClientSideRenderer>
			<NavigableModal
				hash="tour-lightbox-modal"
				isModalOpen={isOpen}
				closeCallback={closeLightbox}
				type={MODAL_TYPES.FULL_SCREEN}
			>
				<iframe
					style={{
						height: `${window.innerHeight - 56}px`,
						width: `${window.innerWidth}px`,
					}}
					src={`${url}&play=1`}
					title={url}
					frameBorder="0"
					allow="xr-spatial-tracking"
				/>
			</NavigableModal>
		</ClientSideRenderer>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	url: state.media.tourUrl,
	isOpen: state.media.spotlightModalOpen,
});

const mapDispatchToProps = (dispatch: Function) => {
	return {
		showTourModalMedia: (show: boolean, url: string) =>
			dispatch(showTourModal(show, url)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TourLightbox);
