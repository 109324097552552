import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import { DisplayLink } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { useCallback } from 'react';
import { Decorated } from 'types/vendor';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';

import Styles from './styles.scss';

export const RelatedSearches = () => {
	const cityUrl = useAppSelector(
		(state) => state.vendor.vendor?.breadcrumbInfo?.cityUrl,
	);
	const categoryName = useAppSelector((state) => state.category.name);
	const searchPageLocation = useAppSelector(
		(state) => state.searchPageLocation,
	);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const { city, stateCode } = searchPageLocation;
	const text = `Explore other ${categoryName.toLowerCase()} in ${city} ${stateCode}`;
	const { track } = useAnalyticsContext();

	const onClickHandler = useCallback(() => {
		const vendorAnalytics = new VendorAnalytics(vendor);

		track({
			event: 'Explore Other Vendor Clicked',
			properties: {
				...vendorAnalytics.baseEventProps(),
				storefrontSection: 'About Vendor',
			},
		});
	}, [track, vendor]);

	if (!cityUrl) {
		return null;
	}

	return (
		<div className={Styles.relatedSearches}>
			<DisplayLink
				href={cityUrl}
				title={text}
				onClick={onClickHandler}
				className={Styles.cityLink}
			>
				<Icon className={Styles.searchIcon} name="search_2" size="sm" />
				<Body1 bold className={Styles.searchText}>
					{text}
				</Body1>
				<Icon className={Styles.carretIcon} name="caret_right" size="sm" />
			</DisplayLink>
		</div>
	);
};
