import { Button } from '@xo-union/tk-component-buttons';
import Spinner from '@xo-union/tk-component-spinner';
import React, { FC, memo, useMemo } from 'react';
import { useStorefrontFeatureScannability } from '../../../../../hooks/useStorefrontFeatureScannability';
import Styles from '../styles.scss';

interface Props {
	isFetchingMore: boolean;
	amountRendered: number;
	totalReviewsCount: number;
	viewMoreOrFetchReviews: () => void;
}

export const ViewMoreReviews: FC<Props> = memo(
	({
		isFetchingMore,
		amountRendered,
		totalReviewsCount,
		viewMoreOrFetchReviews,
	}) => {
		const shouldShowScannability = useStorefrontFeatureScannability();

		const allReviewsShown = useMemo(() => {
			return amountRendered >= totalReviewsCount;
		}, [amountRendered, totalReviewsCount]);

		if (allReviewsShown) {
			return null;
		}

		if (isFetchingMore) {
			return amountRendered <= 0 ? (
				<div className={Styles.loader} data-testid="view-more-loader" />
			) : (
				<Spinner />
			);
		}

		return (
			<Button
				color="secondary"
				size="md"
				onClick={viewMoreOrFetchReviews}
				{...(shouldShowScannability && { block: 'xs' })}
			>
				View more
			</Button>
		);
	},
);
