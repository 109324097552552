import { Body1, H3 } from '@xo-union/tk-ui-typography';
import React, { FC, useCallback, useState } from 'react';

import { AboutAwards } from '@components/about-awards/about-awards';
import { AboutCta } from '@components/about-cta/about-cta';
import { AboutSpotlight } from '@components/about-spotlight/about-spotlight';
import { ReadMore } from '@components/read-more/ReadMore';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import Styles from './styles.scss';

export interface Props {
	headline?: string | null;
	description?: string | null;
	vendorName: string;
}

interface HeaderProps {
	isSentenceCase: boolean;
	isDetailedPricing: boolean;
	isMobile: boolean;
}

const Header = ({
	isSentenceCase,
	isDetailedPricing,
	isMobile,
}: HeaderProps) => {
	const aboutTrackingRef = useTrackStorefrontImpression('About vendor');

	if (isMobile && isDetailedPricing) {
		return (
			<H3 className={Styles.aboutHead} ref={aboutTrackingRef}>
				About
			</H3>
		);
	}

	return (
		<H3 className={Styles.aboutHead} ref={aboutTrackingRef}>
			{isSentenceCase ? 'About this vendor' : 'About This Vendor'}
		</H3>
	);
};

interface DefaultMessageProps {
	vendorName: string;
}
const DefaultMessage = ({ vendorName }: DefaultMessageProps) => (
	<div className={Styles.defaultAbout}>
		We don‘t know {vendorName}‘s story by heart. Message them to get to know
		more about their business.
	</div>
);

const Headline = ({ headline }: { headline: string | null }) => {
	const isLiteStorefront = useLiteStorefront();

	if (!headline || isLiteStorefront) {
		return null;
	}

	return (
		<Body1 className={Styles.headline} bold>
			{headline}
		</Body1>
	);
};

interface AboutContentProps {
	headline: string | null;
	description: string;
	isReadMore: boolean;
}

const defaultClampCharacters = (description: string, isMobile: boolean) => {
	if (isMobile) {
		return description.length >= 300;
	}

	return description.length >= 550;
};

const AboutContent = ({
	headline,
	description,
	isReadMore,
}: AboutContentProps) => {
	const [expanded, setExpanded] = useState(false);
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const shouldShowScannability = useStorefrontFeatureScannability();
	const track = useTrackStorefrontInteraction('About Vendor');

	const trackSeeMore = useCallback(() => {
		track(expanded ? 'Hide' : 'Read more');
	}, [track, expanded]);

	if (isReadMore) {
		return (
			<div className={Styles.aboutContent}>
				<Headline headline={headline} />
				<ReadMore
					clamp
					clampDefaultShowLink={defaultClampCharacters(description, isMobile)}
					clampLines={isMobile ? 8 : 6}
					classes={{
						container: Styles.readMoreContainer,
					}}
					linkColor={shouldShowScannability ? 'secondary' : 'primary'}
					expanded={expanded}
					setExpanded={setExpanded}
					onToggle={trackSeeMore}
				>
					<Body1 className={Styles.desc}>{description}</Body1>
				</ReadMore>
			</div>
		);
	}

	return (
		<div>
			<Headline headline={headline} />
			<Body1 className={Styles.desc}>{description}</Body1>
		</div>
	);
};

const VendorDescription: FC<Props> = ({
	headline = null,
	description = null,
	vendorName,
}) => {
	const isMobile = useAppSelector(selectIsMobile);
	const isInDetailedPricing = useDetailedPricing();
	const isLiteStorefront = useLiteStorefront();

	return (
		<div className={Styles.aboutContainer}>
			<Header
				isSentenceCase
				isDetailedPricing={isInDetailedPricing}
				isMobile={isMobile}
			/>
			<div className={Styles.contentWrapper}>
				<AboutSpotlight />
				<div className={Styles.aboutContentContainer}>
					{description ? (
						<AboutContent
							headline={headline}
							description={description}
							isReadMore
						/>
					) : (
						<DefaultMessage vendorName={vendorName} />
					)}
					{isInDetailedPricing && !isLiteStorefront && (
						<div>
							<AboutCta />
						</div>
					)}
				</div>
				<AboutAwards />
			</div>
		</div>
	);
};

export default VendorDescription;
