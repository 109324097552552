import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { H3 } from '@xo-union/tk-ui-typography';
import React, { FC, useMemo } from 'react';
import Amenities from './components/amenities';
import Detail from './components/detail';

import { useAppSelector } from '@redux/hooks';
import { Decorated } from 'types/vendor';
import { NavItemProps } from '../nav/types';
import Styles from './styles.scss';

import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';

interface HeaderProps {
	vendor: Decorated;
}

const Header = ({ vendor }: HeaderProps) => {
	const detailsTrackingRef = useTrackStorefrontImpression('What we offer');

	const text = useMemo(() => {
		if (vendor.categoryCode === 'REC') {
			return 'Amenities + details';
		}

		return 'Details';
	}, [vendor]);

	return (
		<H3 className={Styles.title} ref={detailsTrackingRef}>
			{text}
		</H3>
	);
};

interface ContentProps {
	vendor: Decorated;
}

const Content = ({ vendor }: ContentProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();

	const venueAmenities = useAppSelector(
		(state) => state.settings.venueAmenities,
	);

	return (
		<div className={Styles.contentContainer}>
			<Amenities
				vendor={vendor}
				venueAmenities={venueAmenities}
				shouldShowScannability={shouldShowScannability}
			/>
			<Detail vendor={vendor} />
		</div>
	);
};

const Details: FC<NavItemProps> = () => {
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as unknown as Decorated,
	);

	const shouldShowScannability = useStorefrontFeatureScannability();

	return (
		<div>
			<Header vendor={vendor} />
			<Content vendor={vendor} />
			{shouldShowScannability && (
				<HighlightedRequest
					messageText="Any questions?"
					buttonText="Start a conversation"
					initiator="Details CTA"
					hideIfLead={true}
				/>
			)}
		</div>
	);
};

export default Details;
