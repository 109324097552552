import React, { ReactElement } from 'react';
import { Decorated } from 'types/vendor';
import { NavItemElements, NavItemProps } from '../types';
import NavSection from './nav_section';

export interface NavSectionsProps {
	children: NavItemElements;
	vendor: Decorated;
}

export const NavSections = ({ children, vendor }: NavSectionsProps) => {
	return (
		<>
			{children.reduce<Array<ReactElement<NavItemProps> | null | undefined>>(
				(acc, child) => {
					if (child) {
						acc.push(
							<NavSection
								key={child.props.title}
								navId={`nav${child.props.title}`}
								shouldHide={!!child.props.shouldHide}
								className={child.props.className}
							>
								{React.cloneElement(child, { vendor })}
							</NavSection>,
						);
					}
					return acc;
				},
				[],
			)}
		</>
	);
};
