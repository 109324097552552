import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useAppSelector } from '@redux/hooks';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Decorated } from 'types/vendor';
import { NavItemProps } from '../nav/types';
import VendorDescription from '../vendor_description';
import VendorPledge from '../vendor_pledge';
import { RelatedSearches } from './components/related_searches';
import Styles from './styles.scss';

export const VendorAbout: FC<NavItemProps> = () => {
	const isLiteStorefront = useLiteStorefront();
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as unknown as Decorated,
	);
	const { headline, description, name, pledges } = vendor;

	return (
		<div
			className={classNames(Styles.aboutWrapper, {
				[Styles.aboutPledge]: pledges?.plantation,
			})}
			data-testid="about-wrapper"
		>
			<VendorDescription
				headline={headline}
				description={description}
				vendorName={name}
			/>
			{pledges?.plantation && <VendorPledge />}
			{isLiteStorefront && <RelatedSearches />}
		</div>
	);
};
